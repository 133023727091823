import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import "../styles/styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMap, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactMap from "../components/map"
import ContactEstimateForm from "../components/contactEstimateForm"

const IndexPage = ({ data, location }) => (
  <Layout>
    <SEO title="Seattle Direct Mail Marketing" />
    <section className="hero is-medium has-bg-img">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-6">
              <span className="is-size-1 has-text-white has-text-weight-bold">
                Direct Mail Marketing
              </span>
              <div className="seprator-line" />
              <p className="is-size-4 has-text-white mb-1">
                Direct mail marketing is a great way to deliver your message to
                targeted prospects and current customers.
              </p>
            </div>
            <div className="column is-5 is-offset-1">
              <ContactEstimateForm
                location={location.pathname}
                href={location.href}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pt-5 px-3">
        <div className="columns is-vcentered">
          <div className="column is-5">
            <Img fluid={data.homeImage.childImageSharp.fluid} />
          </div>
          <div className="column is-6 is-offset-1">
            <h1 className="has-text-grey-light is-size-6 mb-3">
              MOST TRUSTED SEATTLE DIRECT MAIL COMPANY
            </h1>
            <h2 className="has-text-black is-size-1 is-size-2-mobile has-text-weight-bold mb-3">
              Why Direct <br />
              Mail Marketing?
            </h2>
            <div className="seprator-line" />
            <p className="mb-3 is-size-4">
              Cutting through today's massive amounts of advertising can be
              daunting. Sometimes, you need to reach a prospect seven, eight or
              nine times before they respond. Direct Mail is delivered daily,
              collected regularly and read consistently.
            </p>
            <p className="mb-3 is-size-4">
              With Direct Mail Marketing, recipients can keep physical reminders
              of your business and services on file, stuck to their refrigerator
              or pinned to their calendar. Arrive in the mail and be on hand
              when needed.
            </p>
            <Link className="button is-danger is-large" to={"/contact"}>
              Get Free Quote
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="hero is-primary is-fullheight mt-6">
      <div className="hero-head pt-6">
        <div className="container has-text-centered">
          <span className="has-text-white is-size-5">WHAT WE DO</span>
          <h3 className="is-size-1 has-text-white mb-5">
            Direct Mail Services
          </h3>
          <div className="seprator-line is-centered" />
        </div>
      </div>
      <div className="hero-body pt-5">
        <div className="container">
          <div className="columns">
            <div className="column is-half-tablet is-one-third-desktop">
              <Img fluid={data.directMailPostcard.childImageSharp.fluid} />
              <Link
                className="button is-warning is-fullwidth is-radiusless"
                to={"/direct-mail/direct-mail-postcards"}
              >
                Direct Mail Postcards
              </Link>
            </div>
            <div className="column is-half-tablet is-one-third-desktop">
              <Img fluid={data.directMailLetter.childImageSharp.fluid} />
              <Link
                className="button is-warning is-fullwidth is-radiusless"
                to={"/direct-mail/direct-mail-letters"}
              >
                Direct Mail Letters
              </Link>
            </div>
            <div className="column is-half-tablet is-one-third-desktop">
              <Img fluid={data.eddmPostcard.childImageSharp.fluid} />
              <Link
                className="button is-warning is-fullwidth is-radiusless"
                to={"/direct-mail/every-door-direct-mail"}
              >
                Every Door Direct Mail
              </Link>
            </div>
          </div>
          <div className="columns">
            <div className="column is-4 is-offset-4">
              <Link
                className="button is-danger is-large is-fullwidth"
                to={"/direct-mail"}
              >
                See More Services
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="hero is-link">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <p className="has-text-white title">
                Call us today to speak with a Direct Mail Marketing Expert!
              </p>
            </div>
            <div className="column is-4 is-offset-2">
              {/* have a switch here based on view => if mobile, put call link, if desktop put contact us link */}
              <a
                href="tel:253-872-3552"
                className="button is-large is-fullwidth is-danger"
              >
                Call Now!
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pt-5 px-3">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <h1 className="has-text-grey-light is-size-6 mb-3">
              MOST TRUSTED SEATTLE MAILING COMPNAY
            </h1>
            <h2 className="has-text-black is-size-1 is-size-2-mobile has-text-weight-bold mb-3">
              Why People <br />
              Choose Us
            </h2>
            <div className="seprator-line" />
            <p className="mb-3 is-size-4">
              Ditch the "back to the drawing board" step by including us early.
              We'll work with your marketer, designer and printer. Our skill
              will help you create a print ready mail piece on the first go
              around - saving you time and money.
            </p>
            <p className="mb-3 is-size-4">
              Postal regulation requirements are mind bending. You could learn
              them, but do you want to? We know that stuff so you don't have to.
              Our expertise will help you get your mailing on its way FAST - and
              at the lowest possible cost.
            </p>
          </div>
          <div className="column is-5 is-offset-1">
            <Img fluid={data.residentialImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
    <section className="hero mt-6 is-medium is-primary">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <span className="is-size-1 has-text-white">Get In Touch!</span>
              <div className="seprator-line" />
              <p className="has-text-weight-bold is-size-4 mt-6">
                <FontAwesomeIcon icon={faMap} size="1x" className="mr-3" />{" "}
                Location
              </p>
              <p className="is-size-5">21319 68th Ave. South Kent, WA 98032</p>
              <hr />
              <p className="has-text-weight-bold is-size-4 mt-6">
                <FontAwesomeIcon icon={faPhone} size="1x" className="mr-3" />{" "}
                Phone
              </p>
              <a href="tel:253-872-3552" className="is-size-5">
                253-872-3552
              </a>
              <hr />
              <p className="has-text-weight-bold is-size-4 mt-6">
                <FontAwesomeIcon icon={faEnvelope} size="1x" className="mr-3" />{" "}
                Email
              </p>
              <a href="mailto:chad@mail-ad.com" className="is-size-5">
                info@mail-ad.com
              </a>
            </div>
            <div className="column is-5 is-offset-1">
              <ContactEstimateForm
                location={location.pathname}
                href={location.href}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ContactMap
      lat={47.4107725}
      lng={-122.2529673}
      zoom={12}
      controls={false}
    />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    homeImage: file(relativePath: { eq: "why-direct-mail-marketing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    directMailPostcard: file(
      relativePath: { eq: "direct-mail-postcards-services.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 285) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    directMailLetter: file(relativePath: { eq: "direct-mail-letters.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 285) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eddmPostcard: file(
      relativePath: { eq: "seattle-direct-mail-postcards.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 285) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    residentialImage: file(
      relativePath: { eq: "direct-mail-marketing-company.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
